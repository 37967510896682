import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/offplan_brochure.json"
import DefaultForm from "../forms/default-form-layout"
import "./OffplanDetailsBanner.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const OffplanDetailsBanner = ({ offplanData }) => {
  const [show, setShow] = useState(false)
  const imagename = "new-developments.images.details"
  let processedImages = JSON.stringify({})
  if (offplanData?.imagetransforms) {
    processedImages = offplanData?.imagetransforms?.images_Transforms
  }

  const imageupload=offplanData?.upload_image
  const image = imageupload?imageupload:offplanData?.images?.strapi_json_value[0]
  const brochureUrl =
    offplanData?.property_brochure?.length > 0 &&
    offplanData?.property_brochure[0]?.url

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const moveToForm = () => {
    const getId = document.getElementById("offplan-contact-form")
    getId.scrollIntoView({ behavior: "smooth" })
  }

  const formTitle = "Download the Brochure"
  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <div className="offplan-details-banner">
      <ImageModule
        ImageSrc={image}
        altText={`${
          offplanData?.alternativeText
            ? offplanData?.alternativeText
            : offplanData.banner_title
        } banner`}
        imagetransforms={processedImages}
        renderer="bgImg"
        imagename={imagename}
        strapi_id={offplanData?.strapi_id}
        classNames="img-fluid banner-img"
      />
      <div className="overlay-bg"></div>
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="banner-content-section">
            <div className="banner-content">
              <h1>{offplanData?.banner_title}</h1>
              <p className="developer">
                by <span>{offplanData?.developer}</span>
              </p>
              <p className="description">
                <ContentModule
                  Content={offplanData?.banner_content?.data?.banner_content}
                />
              </p>
              <div className="cta-section">
                <a onClick={moveToForm} className="button button-filled-green">
                  register interest
                </a>

                {brochureUrl && (
                  <a
                    className="button button-outline-white"
                    href="javascript:void(0)"
                    onClick={handleModal}
                  >
                    download brochure
                  </a>
                )}
              </div>
            </div>
          </div>
        </Container>
      </ScrollAnimation>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form news_subscribe offplan-brochure"
      >
        <Modal.Body>
          <div className="popup-form-wrapper news_subscribe">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                sourceUrl={pageurl}
                fields={FormFields}
                prop_address={offplanData?.banner_title}
                formTitle={formTitle}
                brochureUrl={brochureUrl}
                to_email_id={
                  offplanData?.additional_email
                    ? offplanData?.additional_email +
                      ", hauswebsiteleads@gmail.com, stephen.n@hausandhaus.com"
                    : "hauswebsiteleads@gmail.com, stephen.n@hausandhaus.com"
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default OffplanDetailsBanner
