import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import GuidesCardComponent from "../components/GuidesCardComponent/GuidesCardComponent"
import LandingContent from "../components/LandingContent/LandingContent"
import OffplanCardSlider from "../components/OffplanCardSlider/OffplanCardSlider"
import OffplanColumnBlocks from "../components/OffplanColumnBlocks/OffplanColumnBlocks"
import OffplanContact from "../components/OffplanContact/OffplanContact"
import OffplanDetailGallery from "../components/OffplanDetailGallery/OffplanDetailGallery"
import OffplanDetailIcons from "../components/OffplanDetailIcons/OffplanDetailIcons"
import OffplanDetailsBanner from "../components/OffplanDetailsBanner/OffplanDetailsBanner"
import PaymentBlock from "../components/PaymentBlock/PaymentBlock"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import sitelogoimage from "../images/logo.svg"
const { Site_Vars } = require("../common/site/config")
const NewDevelopmentDetails = props => {
  const [shareIcons, setShareIcons] = useState(false)
  const offplanData = props.data?.strapiNewDevelopments
  const propertyData = props?.data?.allStrapiNewDevelopments?.edges
  const openGallery = props?.location?.state?.openGallery
  const dynamicData = {
    area: offplanData?.area,
    department: offplanData?.department,
    searchType: "sales",
  }

  const popUpData = props?.data?.strapiNewDevelopments?.popup_data

  const whatsappData =
    props?.data?.allStrapiSiteConfig?.edges?.length > 0 &&
    props?.data?.allStrapiSiteConfig?.edges[0]

  useEffect(() => {
    if (openGallery) {
      const getId = document.getElementById("off_plan_gallery")
      getId.scrollIntoView({ behavior: "smooth" })
    }
  }, [openGallery])
  const { isMobile, isLargeScreen } = useDeviceMedia()

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }
  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <Layout popUpData={popUpData} dynamicData={dynamicData}>
      <OffplanDetailsBanner offplanData={offplanData} />
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            department={"new_developments"}
            alias={"sales"}
            type="property-details-page"
            pagename={offplanData?.project_title}
          />
          <div
            className="share-property-details"
            onClick={() => openShareicons()}
          >
            <i className="icon grey-share-icon" />
            <span>{isMobile ? "Share" : "Share Property"}</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={shareurl}
              />
            )}
          </div>
        </div>
      </Container>

      <OffplanDetailIcons iconData={offplanData?.icon_items} />
      <OffplanDetailGallery
        openGallery={props?.location?.state?.openGallery}
        imageData={offplanData?.images}
        offplanData={offplanData}
      />
      <LandingContent data={offplanData?.text_block} />

      {offplanData?.column_blocks?.left_right_block?.length > 0 && (
        <OffplanColumnBlocks
          data={offplanData?.column_blocks}
          offplanData={offplanData}
        />
      )}
      {offplanData?.payment_block&&
      <PaymentBlock paymentData={offplanData?.payment_block} />
      }
      <OffplanContact offplanData={offplanData} whatsappData={whatsappData} />
      <OffplanCardSlider
        offplanData={offplanData}
        propertyData={propertyData}
      />

      <GuidesCardComponent
        data={offplanData?.property_cards}
        offplanData={offplanData}
      />
    </Layout>
  )
}

export const Head = props => {
  //seo title, h1 and desc prepare, this can send to utilis function later
  const seoData = props.data?.strapiNewDevelopments
  const pageData = props.data?.strapiNewDevelopments
  let pagetitle = `Offplan | ${seoData.development_type} for sale in ${seoData?.project_title}`
  let pagemetadesc = `Offplan | ${seoData.development_type} for sale in ${seoData?.project_title}`
  let pageUrl =
    process.env.GATSBY_SITE_URL +
    (props?.location?.pathname).replace(/\/?$/, "/")
  let myimgtransforms = ""
  if (props.data.strapiNewDevelopments?.imagetransforms?.images_Transforms) {
    myimgtransforms = JSON.parse(
      props.data.strapiNewDevelopments?.imagetransforms?.images_Transforms
    )
  }

  let imageUrlData=pageData?.images?.strapi_json_value?.length>0?pageData?.images?.strapi_json_value[0]?.url:""
  let imageExtension=imageUrlData?.split(".")
  let ext=imageExtension?.length>0?imageExtension?.pop():""

  let pageImg = sitelogoimage
  if (Object.keys(myimgtransforms)?.length > 0) {
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform][ext?ext:"webp"] !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform][ext?ext:"webp"])
        break
      }
    }
    if (pageImg_filt.length > 0) pageImg = pageImg_filt[0]
  }

  var ldJson = {
    "@context": "https://schema.org",
    "@type": "SingleFamilyResidenc",
    name: pageData?.banner_title,
    description: pagemetadesc,
    numberOfRooms: pageData?.development_type,
    occupancy: {
      "@type": "QuantitativeValue",
      minValue: 1,
      maxValue: 5,
    },
    numberOfBedrooms: pageData?.development_type,
    address: {
      "@type": "PostalAddress",
      addressCountry: "Dubai",
      addressLocality: pageData?.area,
    },
  }



  const imageUrl=pageImg;
  // const imageUrl=seoData?.images?.strapi_json_value?.length>0?seoData?.images?.strapi_json_value[0]?.url:""

  return (
    <Seo title={pagetitle} description={pagemetadesc} imageUrl={imageUrl}>
      {/* <meta name="image" content={pageImg} />
      <meta name="twitter:image" content={pageImg} /> */}
      <meta name="og:url" content={pageUrl} />
      <meta name="twitter:url" content={pageUrl} />
      <link rel="canonical" href={pageUrl} />
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Seo>
  )
}

export default NewDevelopmentDetails

export const query = graphql`
  query ($crm_id: String, $area: String) {
    strapiNewDevelopments(crm_id: { eq: $crm_id }) {
      developer
      id
      area
      whatsapp
      additional_email
      department
      development_type
      property_brochure {
        url
      }
      price
      upload_image {
        url
      }
      images {
        strapi_json_value {
          url
        }
      }
      qr_code {
        strapi_json_value {
          validationQr
          permitNumber
        }
      }
      imagetransforms {
        images_Transforms
      }
      longitude
      latitude
      max_price
      project_title
      strapi_id
      slug
      banner_title
      banner_content {
        data {
          banner_content
        }
      }
      form_title
      form_content {
        data {
          form_content
        }
      }

      payment_block {
        payment_items {
          payment_text
          payment_value
        }
        content {
          data {
            content
          }
        }
        title
      }

      property_cards {
        heading
        items {
          custom_link
          content {
            data {
              content
            }
          }
          image {
            url
            alternativeText
          }
          link {
            slug
          }
          link_type
          cta_title
        }
      }

      text_block {
        title
        description {
          data {
            description
          }
        }
      }

      column_blocks {
        left_right_block {
          description {
            data {
              description
            }
          }
          image {
            url
            alternativeText
          }
          longitude
          latitude
          title
          layout
          video_url
        }
      }

      popup_data {
        content {
          data {
            content
          }
        }
        cta {
          custom_link
          link_type
          title
          link {
            slug
            strapi_parent {
              slug
            }
          }
        }
        time_count
        title
        show_popup
        image {
          url
        }
      }

      icon_items {
        title
        content {
          data {
            content
          }
        }
        icon_list {
          sub_content {
            data {
              sub_content
            }
          }
          image {
            url
            alternativeText
          }
          heading
          ctas {
            custom_link
            link_type
            title
          }
        }
      }
    }
    allStrapiNewDevelopments(
      filter: { area: { eq: $area }, crm_id: { ne: $crm_id } }
      limit: 4
    ) {
      edges {
        node {
          developer
          id
          area
          crm_id
          banner_title
          department
          development_type
          images {
            strapi_json_value {
              url
            }
          }
          imagetransforms {
            images_Transforms
          }
          project_title
          strapi_id
          slug
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          offplan_whatsapp_msg
        }
      }
    }
  }
`
